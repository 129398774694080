<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showAddNew="true"
                title="Specification list"
                @onClickAddNewButton="openSpecificationAddModal"
            />

            <hr style="background: #d0d0d0">

            <div class="mb-1 row">
                <label for="colFormLabelLg" class="col-12 col-md-1 col-form-label-lg">Select</label>
                <div class="col-12 col-md-6 col-lg-5 col-xl-4">
                    <v-select
                        v-model="selectedSpecGroup"
                        @option:selected="onChangeSpecGroup"
                        :options="specArr"
                    />
                </div>
            </div>
        </div>

        <h4 class="mt-5 text-secondary px-1">Specification list</h4>
        <div class="row mt-1 px-2">
            <template v-for="spec in specificationValueList" :key="spec.id">
                <!-- <span class="badge me-1 mt-1 rounded-pill badge-glow bg-secondary">{{spec.name}} 
                    <img
                    
                        class="ml-1 cursor-pointer" 
                        width="10" src="/app-assets/images/icons/pencil-alt-solid.svg" 
                        alt=""
                    >
                </span> -->
                <div  @click="onClickEdit(spec)" class="col-lg-3 col-sm-6 col-12">
                    <div class="card" style="border: 1px solid #ebe9f1;">
                        <div class="card-header">
                            <div>
                                <!-- <h2 class="fw-bolder mb-0">86%</h2> -->
                                <p class="card-text spec-name">{{spec.name}}</p>
                            </div>
                            <div class="avatar bg-light-primary p-50 m-0">
                                <div class="avatar-content">
                                    <img
                                        style="border-radius: 0"
                                        class="ml-1 cursor-pointer" 
                                        width="15" 
                                        src="/app-assets/images/icons/edit-2.svg" 
                                        alt=""
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <SpecificationAddModal
            :title="title"
            ref="specificationAddModal"
            :clue="specificationList"
            @onClose="clearSpecData"
            @onSubmitSpecification="onSubmitSpecification"
            :formData="specificationAddData"
        />
        <Loader v-if="loading" />
    </div>
</template>

<script setup>
    import { ref, inject, reactive, onMounted, computed  } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import handleInventory from '@/services/modules/inventory'
    import Loader from '@/components/atom/LoaderComponent'
    import SpecificationAddModal from '@/components/molecule/company/inventory/product-service/SpecificationAddModal.vue'
    import TitleButton from '@/components/atom/TitleButton'

    let loading = ref(false)
    let eventType = ''
    let specificationAddData = ref({})
    let specificationList = ref({})
    let specificationValueList = ref({})
    let selectedSpecGroup = ref('')
    let title = ref('Add Specification')
    const specificationAddModal = ref(null)
    const {fetchSpecification, ...rest} = handleInventory()
    const router = useRouter()
    const route = useRoute()
    const showError =  inject('showError');
    const showSuccess =  inject('showSuccess');
    const companyId = computed(() => {
        return route.params.companyId
    })
    const specArr = computed(() => {
        return Object.keys(specificationList.value)
    })

    function openSpecificationAddModal() {
        title.value = 'Add Specification'
        eventType = 'add'
        specificationAddData.value = {
            company_id: companyId,
            clue: selectedSpecGroup,
            name: '',
            description: ''
        }
        specificationAddModal.value.toggleModal()
    }

    function onClickEdit(spec) {
        title.value = 'Edit Specification'
        eventType = 'edit'
        specificationAddData.value = {
            company_id: companyId,
            id:spec.id,
            clue: selectedSpecGroup,
            name: spec.name,
            description: '',
            _method: 'PATCH'
        }
        specificationAddModal.value.toggleModal()
    }

    function clearSpecData () {
        //specificationAddData.value = {}
    }

    function onChangeSpecGroup (e) {
        loading.value = true
        rest.fetchSpecificationValue(e, companyId.value).then(res => {
            specificationValueList.value = res.data
            loading.value=false
        }).catch((err) => {
            handleError(err)
        })
    }

    function handleError(err) {
        specificationAddModal.value.loading = false
        if(!err.response) {
            showError("Something went wrong")
            return
        }
        if(err.response?.data) {
            showError(err.response.data.message)
        }
    }

    function onSubmitSpecification () {
        specificationAddModal.value.loading = true
        if(eventType == 'add') {
            rest.storeSpecification(specificationAddData.value)
            .then(res => {
                showSuccess(res.message)
                specificationAddModal.value.loading = false
                specificationAddModal.value.toggleModal()
                onChangeSpecGroup(selectedSpecGroup.value)
            })
            .catch(err => {handleError(err)})
        }
        if(eventType == 'edit') {
            rest.updateSpecification(specificationAddData.value)
            .then(res => {
                showSuccess(res.message)
                specificationAddModal.value.loading = false
                specificationAddModal.value.toggleModal()
                onChangeSpecGroup(selectedSpecGroup.value)
            })
            .catch(err => {handleError(err)})
        }
    }

    onMounted(() => {
        loading.value = true
        fetchSpecification(companyId.value).then(res => {
            specificationList.value = res.data
            if(res.data) return res.data
        })
        .then(data => {
           let dataArr = Object.keys(data)
           selectedSpecGroup.value = dataArr[0]
           if(dataArr.length) onChangeSpecGroup(dataArr[0], companyId.value)
        })
        .catch((err) => {
            handleError(err)
        })
        .finally(() => {loading.value=false});
    }) 
</script>

<style scoped>
.spec-name {
    /* width: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
</style>